import React from 'react';
import PageSection from '../ui/PageSection';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './PageHeader.module.scss';

interface PageHeaderProps {
  top?: React.ReactNode;
  title: string;
  description: React.ReactNode;
}

const PageHeader: React.SFC<PageHeaderProps> = ({
  top,
  title,
  description,
}) => {
  return (
    <PageSection>
      <Spacing top={5}>
        <Text center>
          {top && <Spacing bottom={4}>{top}</Spacing>}
          <Text darker>
            <h1>{title}</h1>
          </Text>
          <Spacing top={4}>
            <div className={styles.description}>{description}</div>
          </Spacing>
        </Text>
      </Spacing>
    </PageSection>
  );
};

export default PageHeader;
