import React from 'react';
import { Waypoint } from 'react-waypoint';
import Spacing from '../ui/Spacing';
import Layout from './Layout';
import MarketingFooter from './MarketingFooter';
import Meta from './Meta';
import NavBar from './NavBar';
import { NavbarBackgroundColor } from '../../types';

interface MarketingLayoutProps {
  children: React.ReactNode;
  metaTitle?: string;
  navbarShowRegister?: boolean;
  noStickyNavbar?: boolean;
  noFooterPadding?: boolean;
  hideNavbarLinks?: boolean;
  navbarBackground?: NavbarBackgroundColor;
  title?: string;
  description?: string;
  image?: string;
  noindex?: boolean;
}

interface MarketingLayoutState {
  showingStickyNavbar: boolean;
}

class MarketingLayout extends React.Component<
  MarketingLayoutProps,
  MarketingLayoutState
> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state: MarketingLayoutState = {
    showingStickyNavbar: false,
  };

  render() {
    const {
      children,
      // TODO: Fix this the next time the file is edited.
      /* eslint-disable @typescript-eslint/naming-convention */
      hideNavbarLinks = false,
      navbarShowRegister = true,
      noStickyNavbar = false,
      noFooterPadding = false,
      /* eslint-enable @typescript-eslint/naming-convention */
      navbarBackground,
      title,
      description,
      image,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      noindex,
    } = this.props;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { showingStickyNavbar } = this.state;
    const metaTitle =
      this.props.metaTitle || (title && `${title} - Slab`) || undefined;

    return (
      <Layout>
        <Meta
          title={metaTitle}
          description={description}
          image={image}
          noindex={noindex}
        />
        <NavBar
          hideLinks={hideNavbarLinks}
          background={showingStickyNavbar ? 'white' : navbarBackground}
          showRegister={navbarShowRegister}
          sticky={showingStickyNavbar}
        />
        {!noStickyNavbar && (
          <Waypoint
            onPositionChange={({ currentPosition }) =>
              this.setState({
                showingStickyNavbar: currentPosition === 'above',
              })
            }
            topOffset={-400}
          />
        )}
        {children}
        <Spacing top={noFooterPadding ? undefined : 10}>
          <MarketingFooter />
        </Spacing>
      </Layout>
    );
  }
}

export default MarketingLayout;
