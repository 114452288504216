import classNames from 'classnames';
import React from 'react';
import styles from './Row.module.scss';

interface RowProps {
  breakpoint?: 'small' | 'medium';
  children: React.ReactNode;
  even?: boolean;
  gutter?: 'small' | 'medium' | 'large' | 'xlarge';
  inline?: boolean;
  reversed?: boolean;
  centered?: boolean;
  verticalCentered?: boolean;
}

const Row: React.SFC<RowProps> = ({
  breakpoint = 'small',
  children,
  even,
  gutter = 'large',
  inline,
  reversed,
  centered,
  verticalCentered,
}) => (
  <div
    className={classNames(styles.row, {
      [styles.breakpointSmall]: breakpoint === 'small',
      [styles.breakpointMedium]: breakpoint === 'medium',
      [styles.even]: even,
      [styles.gutterXLarge]: gutter === 'xlarge',
      [styles.gutterLarge]: gutter === 'large',
      [styles.gutterMedium]: gutter === 'medium',
      [styles.gutterSmall]: gutter === 'small',
      [styles.inline]: inline,
      [styles.reversed]: reversed,
      [styles.centered]: centered,
      [styles.verticalCentered]: verticalCentered,
    })}
  >
    {children}
  </div>
);

export default Row;

interface BoxProps {
  children: React.ReactNode;
  width: number;
}

export const Box: React.SFC<BoxProps> = ({ children, width }) => (
  <div style={{ flex: width }}>{children}</div>
);
